
export default class TimesheetClass {

    data
    ctx

    init(data) {
        console.log('TS started', data)

        this.data = data
        this.ctx = $('#'+CS.currentContextID('CSSubsheetModal'))

        $('.modal-body', this.ctx).html(this.createBaselineHTML())

    }

    save(){
        let data = collectData(this.ctx)

        console.log(data)

        // if(CS.getCurrentSubsheet().id) {
            data['subSheetID'] = 'Timesheet'//CS.getCurrentSubsheet().id
        // }else{
        //     data['colID'] = CS.getCurrentSubsheet().colID
        // }

        CS.clearTrackedChanges()

        CS.makeCall({
            url: route('subsheet.save'),
            data: JSON.stringify(data),
            contentType: 'application/json'
        }, (x) => {
            CS.showSuccess('Saved!')
            CS.handleResponse(x,'save timesheet')
        })
    }

    createBaselineHTML() {
        let out = $('<div>').addClass('inputContainer')

        let stack = $('<div>')
            .addClass('hstack gap-3')

        $(this.data.template[0]).each(function (){
            let inp = CS.createFromInput(this)
            out.append(inp)
        })
        $(this.data.template[1]).each(function (){
            let inp = CS.createFromInput(this)
            stack.append(inp)
        })

        out.append(stack)
        let workers = $('<div>')
        for(let x= 0; x<5;x++)
            $(this.data.template[2]).each(function (){
                let inp = CS.createFromInput(this)
                workers.append(inp)

            })
        out.append(workers, $('<hr>'))

        return out;
    }

    handleResponse(out) {
        if(out.reload)
            CS.reloadSubsheet()
    }
}
