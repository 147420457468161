import {createFromInput} from "../html/forms";
import {createRow} from "../subsheets";

export default class QuoteClass {
    ctx = false
    container = false
    data

    init(resp){
        this.data = resp.data
        this.ctx = $('#'+CS.currentContextID())
        this.container = $('.modal-body', this.ctx)
        $('#saveBtn', this.ctx).on('click', this.save)
        this.buildGui()
        return this
    }

    buildGui(){
        this.container.html('')
        let tabs = [
            {title: 'New', content: this.buildNewTab()},
            {title: 'Existing', content: 'content'},
        ]
        this.container.append(CS.tabs_html(tabs))
    }

    buildNewTab(){
        let tabs = [
            {title: 'General', content: this.buildGeneralTab()},
            {title: 'Demo', content: this.buildTab('demo')},
            {title: 'Takeoffs', content: this.buildTab('takeoffs')},
            {title: 'Notes', content: this.buildTab('notes')},
            {title: 'Separate Price', content: this.buildTab('separate')},
            {title: 'Preview', content: this.buildPreviewTab() },
        ]
        return CS.tabs_html(tabs)
    }

    buildGeneralTab(){
        let cont = $('<div>').addClass('inputContainer')
        for(let x1 in this.data.tableData.general){
            let td = this.data.tableData.general[x1]

            td.value = (this.data.savedData ?? this.data.rawData)['general'][td.name] ?? '?'
            cont.append(createFromInput(td))
        }

        return cont
    }

    buildPreviewTab(){
        let cont = $('<div>').addClass('')

        let prviFrm = $('<div>').addClass('border border-1 border-gray previewTabContainer')
        let prvBtn = $('<div>').addClass('btn btn-success btn-sm')
            .html('Preview')
            .on('click', () => {
                CS.makeCall({
                    url: route('subsheet.quote.preview'),
                    data: this.collectData(),
                }, (d) => {
                    prviFrm.html(d)
                })
            })

        cont.append(prvBtn, prviFrm)
        return cont
    }

    buildTab(t){
        console.log('build tab',t,this.data)
        let x = new CS.MiniTable(this.data.tableData[t], [],{id:t})

        let data = this.data.savedData ?? this.data.rawData


        $(data[t]).each((k, i) => {
            $(i).each((k2, t) => {
                x.addRow(t)
            })

        })

        return x.getHTML()
    }

    collectData(){
        let data = {}
        data.general = CS.collectData(this.ctx).inputs

        $(['takeoffs','notes', 'demo', 'separate']).each((k,x) =>{
            data[x] = CS.collectData($('#'+x, this.ctx)).rowData

        })

        if(CS.getCurrentSubsheet().id) {
            data['subSheetID'] = CS.getCurrentSubsheet().id
        }

        return data
    }

    save(){
        let data = this.collectData()


        CS.clearTrackedChanges()

        CS.makeCall({
            url: route('subsheet.save'),
            data: data,
        }, () => {
            CS.showSuccess('Saved!')
        })

    }


}
