import Sortable from "sortablejs";

export default class InvoiceClass {
    ctx = false

    invoiceTemplate
    tableColumns
    tableRows = []
    tableRowList = false
    balanceDueContainer = false
    supportingData = []
    data

    init(resp, ctx){
        this.tableRows = []
        this.data = resp
        this.invoiceTemplate = resp.data.template
        this.tableColumns = resp.data.tableColumns
        this.supportingData['customers'] = resp.data.customerData

        $('.modal-body', ctx).html(this.createInvoiceBaselineHTML())
        this.ctx = ctx
        this.balanceDueContainer = $('#balanceDueContainer')

        // $('#saveBtn', this.ctx).on('click', this.saveInvoice)

        this.loadInvoice(resp.data)

        return this
    }

    loadInvoicesList(data){
        console.log(data)
    }

    loadInvoice(data){

        if(data.new){

        }

        this.tableRowList = $('<ul>')
            .addClass('')

        for(let x = 0; x < this.invoiceTemplate.length; x++){
            $('#row'+(x+1)).append(
                this.createRow(this.invoiceTemplate[x])
            )
        }

        this.loadTable(data)


        this.loadBalanceDue()
        if(!data.rows || data.rows.length === 0){

            this.addRow()
            this.addRow()
        }


        CS.updateSums()
        this.setDateLogic()
        return this

    }

    setDateLogic(){
        let term = $('[name="term"]')
        term.on('change', ()=>{
            this.calcDueDate()
        })
        this.calcDueDate()

    }

    calcDueDate(){
        let invoiceDate = $('[name="invoiceDate"]')
        let dueDate = $('[name="dueDate"]')
        let term = $('[name="term"]')

        if(invoiceDate.val() === '') {

            let now = new Date()
            invoiceDate.val(now.format())

            let due = now.addDays(term.val())
            dueDate.val(due.format())
        }
    }

    loadBalanceDue(){
        let title = $('<span>').html('BALANCE DUE')
        let h1 = $('<h1>')
            .addClass('total')
            .html('$0.00')

        let out = $('<div>')

        out.append(title).append(h1)

        this.balanceDueContainer.html(out)
    }

    loadTable(data){
        let tableContainer = $('#tableContainer').html('')

        tableContainer.append(this.createTableHeader())

        tableContainer.append(this.tableRowList)
        $(data.rows).each((k,v)=>{
            this.tableRowList.append(this.createTableRow(v))

        })

        Sortable.create(this.tableRowList[0],{
            handle: '.sortHandle',
            animation: 300
        })

        this.loadTableControls()
    }

    save(){
        let data = CS.collectData(this.ctx)

        if(CS.getCurrentSubsheet().id) {
            data['subSheetID'] = CS.getCurrentSubsheet().id
        }else{
            data['colID'] = CS.getCurrentSubsheet().colID
        }

        CS.clearTrackedChanges()

        CS.makeCall({
            url: route('subsheet.save'),
            data: JSON.stringify(data),
            contentType: 'application/json'
        }, () => {
            CS.showSuccess('Saved!')
        })

    }

    addRow(){
        this.tableRowList.append(this.createTableRow())

    }

    loadTableControls(){
        let addRowBtn = $('<span>')
            .addClass('btn btn-outline-secondary btn-sm')
            .html('Add lines')
            .on('click', ()=>{this.addRow()})

        $('#tableControls').append(addRowBtn)
    }

    createTableHeader(){
        let out = $('<div>')
            .addClass('row headerRow')
        let commonClass = 'border-1 border border-top-0 border-gray';

        let leftGutter = $('<div>')
            .addClass('col shortCol')
            .addClass('border-start-0')
            .addClass(commonClass)

        out.append(leftGutter)

        let lineCounter = $('<div>')
            .addClass('col shortCol text-end')
            .addClass(commonClass)
            .html('#')

        out.append(lineCounter)

        $(this.tableColumns).each(function (){
            let col = $('<div>')
                .addClass(this.class)
                .addClass(commonClass)
                .html(this.title)

            out.append(col)
        })


        let rightGutter = $('<div>')
            .addClass('col shortCol')
            .addClass('border-end-0')
            .addClass(commonClass)


        out.append(rightGutter)

        return out
    }

    createTableRow(rowData = []){
        let out = $('<li>')
            .addClass('row dataRow')

        let commonClass = 'border-1 border border-gray';

        let leftGutter = $('<div>')
            .addClass('col shortCol p-2 sortHandle border-start-0')
            .addClass(commonClass)
            .html('<i class="fa-solid fa-list-ul"></i>')

        out.append(leftGutter)

        let lineCounter = $('<div>')
            .addClass('col shortCol text-end p-2')
            .addClass(commonClass)
            .html(this.tableRows.length+1)

        out.append(lineCounter)


        $(this.tableColumns).each((k,t)=>{
            let col = CS.createCol(rowData,t,'')
                .addClass(t.class)
                .addClass(commonClass)


            out.append(col)
        })


        let rightGutter = $('<div>')
            .addClass('col shortCol p-2')
            .addClass('border-end-0')
            .addClass(commonClass)
            .html('<i class="fa-solid fa-trash"></i>')
            .on('click', (k,o)=> {
                this.tableRows.pop()
                out.remove();
            });

        out.append(rightGutter)

        this.tableRows.push(out)

        return out
    }

    createRow(inputs){

        let stack = $('<div>')
            .addClass('hstack gap-3')

        $(inputs).each(function (){
            let inp = CS.createFromInput(this)
            stack.append(inp)
        })


        return stack
    }


    createInvoiceBaselineHTML(){
        let out = $(`<div class="container-fluid" id="invoicesContainer">
        <div class="row">
            <div class="col inputContainer" id="row1"></div>
            <div class="col-2 text-end" id="balanceDueContainer"></div>
        </div>
        <div class="row">
            <div class="col inputContainer" id="row2"></div>
        </div>
        <div class="bg-white">

            <div class="row">
                <div class="col" id="tableContainer"></div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="" id="tableControls"></div>
                </div>
                <div class="col">
                </div>
                <div class="col-3">
                    <div class="" id="totalsContainer">
                        <div class="row" id="">
                            <div class="col">Subtotal</div>
                            <div class="col text-end">
                                <span class="subTotal"></span>
                            </div>
                        </div>
                        <div class="row" id="">
                            <div class="col">HST (ON) @ 13% on <span class="subTotal"></span></div>
                            <div class="col text-end"><input type="text" class="taxTotal text-end"></div>
                        </div>
                        <div class="row" id="">
                            <div class="col">Total</div>
                            <div class="col text-end">
                                <span class="total"></span>
                            </div>
                        </div>
                        <div class="row" id="">
                            <div class="col">Balance due</div>
                            <div class="col text-end">
                                <span class="total"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-3 inputContainer" id="row3"></div>
                <div class="col" id="row4"></div>
            </div>
            <div class="row">
            </div>
        </div>
    </div>`)

        return out
    }



}
