import {uniqueId} from "lodash";

export * from './html/progress';
export * from './html/forms';
export * from './html/spinner';

export function checkBox_html(name, val, text = false){

    if(!text) text = val;
    let base = $('<div>').addClass('form-check form-switch')
    base.append($('<input>').addClass('form-check-input')
        .attr('name', name)
        .attr('value', val)
        .attr('type', 'checkbox'))
    base.append($('<label>').addClass('form-check-label text-truncate').html(text))
    return base

}

export function loadingSpinner_html(){

}


export function tabs_html(tabs){
    let id = uniqueId() //$('.CSTab').length
    let out = $('<div>').addClass('CSTab')
    let ul = $('<ul class="nav nav-tabs mb-3" role="tablist">').attr('id', 'ex'+id+'-content')
    let content = $('<div>').addClass('tab-content').attr('id', 'ex'+id+'-content')

    $(tabs).each((k, tab)=>{

        let li = $('<li>').addClass('nav-item ')
            .attr('role', 'presentation')
        let a = $(`<a
            class="nav-link"
            data-bs-toggle="tab"
            role="tab"

             >`)
            .html(tab.title)
            .attr('id', 'ex'+id+'-tab-'+k)
            .attr('aria-controls', 'ex'+id+'-tabs-'+k)
            .attr('href', '#ex'+id+'-tabs-'+k)

        if(tab.onClick)
            a.on('click', tab.onClick)


        let pane = $('<div>')
            .addClass('tab-pane')
            .attr('role', 'tabpanel')
            .html(tab.content)
            .attr('id', 'ex'+id+'-tabs-'+k)
            .attr('aria-controls', 'ex'+id+'-tabs-'+k)

        if(k < 1) {
            a.attr('aria-selected', "true")
                .addClass('active')
            pane.addClass('active')
        }
        li.append(a)
        ul.append(li)

        content.append(pane)
    })

    out.append(ul, content)
    return out
}

export class Dropdown {
    buttons = []
    title = ''
    constructor(title){
        this.title = title
    }

    add(name, action) {
        this.buttons.push({
            'name': name,
            'action': action
    })
    }

    out() {
        let id = 'dd-'+Math.random().toString(16).slice(2)
        let c = $('<span>').addClass('dropdown')
        let b = $('<button>').addClass('btn btn-sm btn-secondary dropdown-toggle')
            .attr('type', 'button')
            .attr('id', id)
            .attr('aria-expanded', 'false')
            .attr('data-bs-toggle', 'dropdown')
            .html(this.title)

        let u = $('<ul>').addClass("dropdown-menu")
            .attr('aria-labelledby', id)

        $(this.buttons).each((k, button)=>{
            u.append(
                $('<li>').append(
                    $('<a>').addClass('dropdown-item')
                        .attr('href', '#')
                        .on('click', button.action)
                        .html(button.name)
            )
            )
        })

        c.append(b,u)

        return c
// return `
//         <div class="dropdown">
//   <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
//     Dropdown button
//   </button>
//   <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
//     <li><a class="dropdown-item" href="#">Action</a></li>
//     <li><a class="dropdown-item" href="#">Another action</a></li>
//     <li><a class="dropdown-item" href="#">Something else here</a></li>
//   </ul>
// </div>`
    }
}


export function htmlSelect(d){
    let out = $('<select>')
    $(d.opts).each((k, v)=>{
        out.append($('<option>').html(v.name??v.value).attr('value', v.value))
    })
    return out
}


export function inputGroup(d) {
    let out = $('<div>').addClass('input-group input-group-sm')
    $(d).each((k, v)=>{
        if(v.text){
            out.append($('<span>').html(v.text).addClass('input-group-text bg-secondary text-light'))
        }

        if(v.input){
            out.append(v.input.addClass('form-control form-control-sm'))
        }

    })

    return out
}
