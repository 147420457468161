import * as _ from 'lodash';
import axios from 'axios';
import Echo from 'laravel-echo';
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
import Pusher from 'pusher-js';
import * as CS from './calcsys'
import x_spreadsheet from 'x-data-spreadsheet'
import 'bootstrap';
import {Modal, Toast, Offcanvas, Tooltip, Popover} from 'bootstrap';
import datepicker from 'js-datepicker'
import currency from "currency.js";
import konva from "konva";
import PinchZoom from "pinch-zoom-js";
import { nanoid } from 'nanoid'

window.nanoid = nanoid
window.PinchZoom = PinchZoom
window._ = _;
window.currency = currency;
window.$ = window.jQuery = $
import jqueryform from 'jquery-form'
jqueryform($)
// window.fabric = fabric;

window.datepicker = datepicker;
window.Tooltip = Tooltip; //import('bootstrap');
window.Modal = Modal; //import('bootstrap');
window.Toast = Toast; //import('bootstrap');
window.Offcanvas = Offcanvas; //import('bootstrap');
window.Popover = Popover; //import('bootstrap');

window.axios = axios;
window.Sortable = Sortable;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    wsHost: import.meta.env.VITE_PUSHER_HOST,
    wssHost: import.meta.env.VITE_PUSHER_HOST,
    wssPort: import.meta.env.VITE_PUSHER_PORT,
    wsPort: import.meta.env.VITE_PUSHER_PORT,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws','wss'],
});

// window.codemirror = codemirror;
import {EditorView, basicSetup} from "codemirror"
window.EditorView = EditorView;
import {EditorState, Compartment} from "@codemirror/state"
// import {htmlLanguage, html} from "@codemirror/lang-html"
// import {language} from "@codemirror/language"
import {php} from "@codemirror/lang-php"

const languageConf = new Compartment

window.CMExt = function(obj){ return [
    basicSetup,
    languageConf.of(php()),
    EditorView.updateListener.of(function(e) {
        $('#format').val(e.state.doc.toString())
    })
    // autoLanguage
] }



window.CS = CS
window.CS2 = new CS.CalcSys()

window.x_spreadsheet = x_spreadsheet;
